import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import ReactGA from 'react-ga';
import Page from '@components/page';
import VideoModal from '@components/video-modal';
import IntroModal from '@components/intro-modal';
import { Colors, Fonts } from '@styles';
import Assets from '@assets';
import DATA from '@data';

const PersonasContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: ${Colors.white};
  box-shadow: 0 0 14px 0px rgb(0 0 0 / 20%);
  padding: 5% 9%;
  box-sizing: border-box;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding: 8% 9%;
    height: 95%;
  }
`;

const AudioToggle = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top: -70px;

  &:hover {
    opacity: 0.8;
  }
`;

const PersonaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: all linear 0.2s;
  position: relative;
  transform: scale(0.98);
  backface-visibility: hidden;

  &:hover {
    transform: ${({ active }) => (active ? null : 'scale(1)')};
  }
`;

const PersonaName = styled.div`
  font-family: ${Fonts.bold};
  color: ${Colors.primary};
  font-size: 1rem;
  margin-bottom: 6px;
`;

const PersonaAge = styled.div`
  color: ${Colors.darkGrey};
  font-size: 1rem;
  margin-bottom: 40px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    margin-bottom: 20px;
  }
`;

const PersonaImageWrapper = styled.div`
  width: 171px;
  height: 171px;
  border-radius: 171px;
  background: ${({ active }) => (active ? Colors.yellow : Colors.grey)};
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 131px;
    height: 131px;
    border-radius: 131px;
  }
`;

const PersonaInformation = styled.div`
  position: absolute;
  top: 270px;
  width: 346px;
  height: 191px;
  background: ${Colors.white};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  z-index: 100;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    top: 220px;
    width: 300px;
    height: 181px;
  }
`;

const PersonaInformationText = styled.div`
  font-size: 14px;
  color: ${Colors.primary};
  display: flex;
  flex: 1;
  line-height: 20px;
  overflow: auto;
  padding-right: 5px;
  margin: 12px 5px 12px 12px;
  cursor: default;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${Colors.scrollbarTrack};
    border-radius: 10px;
    margin: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.primary};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 13px;
  }
`;

const PersonalInformationActionContainer = styled.div`
  width: 44px;
  height: 100%;
  background: ${Colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  > img {
    width: 28px;
    margin-bottom: 5px;
  }

  > span {
    color: ${Colors.white};
    transition: all linear 0.2s;
    font-size: 0.8rem;

    &:hover {
      opacity: 0.9;
    }
  }
`;

const Persona = ({ data, active = false, audioMuted = false, onClick, onVideoClick, onAudioToggleClick }) => {
  const handleOnClick = () => {
    onClick(data.id, !active);
  };

  const handleOnVideoClick = e => {
    e.preventDefault();
    e.stopPropagation();

    onVideoClick();
  };

  const handleOnAudioToggleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    onAudioToggleClick();
  };

  return (
    <PersonaContainer active={active} onClick={handleOnClick}>
      {active ? (
        <AudioToggle
          src={audioMuted ? Assets.images.AudioMutedIcon : Assets.images.AudioIcon}
          onClick={handleOnAudioToggleClick}
        />
      ) : null}
      <PersonaName>{data.name}</PersonaName>
      <PersonaAge>{data.age} years old</PersonaAge>
      <PersonaImageWrapper active={active}>
        <img src={data.image} />
      </PersonaImageWrapper>
      {active ? (
        <PersonaInformation>
          <PersonaInformationText>{data.information}</PersonaInformationText>
          <PersonalInformationActionContainer onClick={handleOnVideoClick}>
            <img src={Assets.images.PlayRoundIcon} />
            <span>Watch</span>
          </PersonalInformationActionContainer>
        </PersonaInformation>
      ) : null}
    </PersonaContainer>
  );
};

function iOS() {
  if (typeof navigator !== 'undefined') {
    return (
      [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  return false;
}

const Glide = () => {
  const pageTitle = 'Glide';
  const pageSubTitle = 'View at least 2 personas and videos to advance';

  const audio = useRef(null);
  const [ showIntroModal, setShowIntroModal ] = useState(false);
  const [ audioMuted, setAudioMuted ] = useState(false);
  const [ completedSteps, setCompletedSteps ] = useState({});
  const [ viewedPersonas, setViewedPersonas ] = useState([]);
  const [ activePersona, setActivePersona ] = useState(null);
  const [ showModal, setShowModal ] = useState(false);
  const rightNavigationActive = viewedPersonas.length >= 2 || completedSteps['glide'];

  useEffect(() => {
    if (localStorage.getItem('session-id')) {
      const glideIntro = window.localStorage.getItem('glide-intro');
      if (!glideIntro || glideIntro != 1) {
        setShowIntroModal(true);
      }

      let steps = window.localStorage.getItem('completed-steps');
      if (steps) {
        steps = JSON.parse(steps);
        setCompletedSteps(steps);

        if (steps.glide === true) {
          setShowIntroModal(false);
        }
      }
    } else {
      navigate('/intro');
    }
  }, []);

  const handleOnPersonaClick = (personaId, active) => {
    const isActive = activePersona === personaId;

    if (!isActive) {
      setActivePersona(active ? personaId : null);
      setShowModal(false);

      if (audio.current) {
        audio.current.pause();
        setAudioMuted(false);
      }

      const persona = DATA.GLIDE.find(p => p.id === personaId);
      audio.current = new Audio(persona.voiceover);
    } else {
      setActivePersona(null);
      setShowModal(false);

      if (audio.current) {
        audio.current.pause();
        setAudioMuted(false);
      }
    }
  };

  const handleOnPersonaVideoClick = () => {
    setShowModal(true);
    audio.current.pause();
  };

  const handleOnAudioToggleClick = () => {
    const newVal = !audioMuted;
    setAudioMuted(newVal);

    if (newVal) {
      audio.current.play();
    } else {
      audio.current.pause();
    }
  };

  const handleOnLeftNavigationPress = () => {
    if (completedSteps['fly']) {
      navigate('/fly?step=4&completed=1,2,3,4');
    } else {
      navigate('/fly');
    }
  };

  const handleOnRightNavigationPress = () => {
    let completedSteps = window.localStorage.getItem('completed-steps');
    if (completedSteps) {
      completedSteps = JSON.parse(completedSteps);
      completedSteps.glide = true;
    }
    window.localStorage.setItem('completed-steps', JSON.stringify(completedSteps));

    navigate('/soar');
  };

  const handleOnModalClose = () => {
    setActivePersona(null);
    setShowModal(false);
  };

  const handleOnVideoFinish = personaId => () => {
    setViewedPersonas([ ...viewedPersonas, personaId ]);

    const persona = DATA.GLIDE.find(p => p.id === activePersona);
    ReactGA.event({
      category: 'GLIDE',
      action: `User viewed "${persona.name}'s" video`,
    });
  };

  const handleIntroModalOnClose = () => {
    setShowIntroModal(false);

    window.localStorage.setItem('glide-intro', 1);
  };

  return (
    <React.Fragment>
      <Page
        layout={iOS() ? '12% 1% 66% 11% 2%' : null}
        title={pageTitle}
        subTitle={pageSubTitle}
        icon={Assets.images.PageIconGlide}
        audio={Assets.audios.PageAudioGlide}
        withNavigation
        fullContent
        showOnTop
        leftNavigationActive={true}
        leftNavigationText="Fly"
        onLeftNavigationPress={handleOnLeftNavigationPress}
        rightNavigationActive={rightNavigationActive}
        rightNavigationText="Soar"
        onRightNavigationPress={handleOnRightNavigationPress}
      >
        {DATA.GLIDE ? (
          <PersonasContainer>
            {DATA.GLIDE.map(persona => (
              <Persona
                key={persona.id}
                data={persona}
                active={activePersona === persona.id}
                audioMuted={audioMuted}
                onClick={handleOnPersonaClick}
                onVideoClick={handleOnPersonaVideoClick}
                onAudioToggleClick={handleOnAudioToggleClick}
              />
            ))}
          </PersonasContainer>
        ) : null}
      </Page>
      {showModal ? (
        <VideoModal
          file={DATA.GLIDE.find(p => p.id === activePersona).file}
          transcript={DATA.GLIDE.find(p => p.id === activePersona).file_transcript}
          onFinish={handleOnVideoFinish(activePersona)}
          onClose={handleOnModalClose}
          autoplay={!iOS()}
        />
      ) : null}
      {showIntroModal ? (
        <IntroModal
          icon={Assets.images.PageIntroIconGlide}
          audio={Assets.audios.PageAudioGlide}
          onClose={handleIntroModalOnClose}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Glide;
