import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import VideoPlayer from '@components/video-player';
import Assets from '@assets';

const VideoModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
`;

const VideoPlayerWrapper = styled.div`
  width: 1075px;
  position: relative;
  justify-content: center;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 850px;
  }
`;

const CloseButton = styled.div`
  z-index: 1001;
  position: absolute;
  cursor: pointer;
  right: -18px;
  top: -18px;
  background: white;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 2px rgb(0 0 0 / 20%);
  transition: all linear 0.3s;

  img {
    width: 14px;
    height: 14px;
  }

  &:hover {
    opacity: 0.9;
  }
`;

function iOS() {
  if (typeof navigator !== 'undefined') {
    return (
      [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  return false;
}

const VideoModal = ({ file, transcript = null, onClose, onFinish }) => {
  const [ videoPath ] = useState(file);

  const handleVideoPlayerOnFinish = () => {
    onFinish();
  };

  return (
    <VideoModalContainer>
      <VideoPlayerWrapper>
        <VideoPlayer
          width="100%"
          modal
          file={videoPath}
          transcript={transcript}
          onFinish={handleVideoPlayerOnFinish}
          autoplay={!iOS()}
        />
        <CloseButton onClick={onClose}>
          <img src={Assets.images.CloseIcon} />
        </CloseButton>
      </VideoPlayerWrapper>
    </VideoModalContainer>
  );
};

export default VideoModal;
